import styled from 'styled-components';
import { minMediaQuery } from '../../config/breakpoints';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  height: 70vh;
  ${minMediaQuery('sm')} {
    font-size: 32px;
  }
`;

export const Message = styled('div')`
  font-size: 24px;
  text-align: center;
  margin: 8px 0;
`;

export const SubMessage = styled('div')`
  font-size: 14px;
  text-align: center;
`;

export const BackHome = styled.a`
  cursor: pointer;
  padding: 20px;
  font-size: 18px;
  color: #006699;

  :hover {
    opacity: .5;
  }
`;