import styled from 'styled-components';
import { minMediaQuery } from '../../config/breakpoints';

export const Wrapper = styled('div')`
  width: 100%;
  box-sizing: border-box;

  .slick-slider {
    width: calc(100% - 80px);
    margin: auto;
  }

  .slick-arrow,
  .slick-prev {
    display: none !important;
  }
`;

export const Heading = styled('h2')`
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to right, #ffffff, #27ccf8, #1868c9);
  padding: 16px;
  margin: 8px 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  ${minMediaQuery('lg')} {
    font-size: 28px;
    margin: 40px 0;
    width: calc(50% - 24px);
    text-align: right;
  }

  span {
    font-weight: bold;
  }
`;

export const LogosWrapper = styled('div')`
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 40px;
`;

export const Logo = styled('div')`
  margin: 16px auto;
  min-height: 80px;
  display: flex;
  justify-content: center;

  ${minMediaQuery('lg')} {
    margin: 16px 8px;
    min-width: 100px;
    width: 5%;
  }
`;

export const Img = styled.div`
  min-height: 60px;
  align-self: center;
  background: ${({ logo }: { logo: string }) => `url("${logo}")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 20px;

  :hover {
    cursor: pointer;
  }

  ${minMediaQuery('lg')} {
    min-height: 100px;
    width: 80px;
  }
`;
