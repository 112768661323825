import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Parse from 'parse/dist/parse.min.js';

import Modal from '../Modal';
import { Wrapper, Textarea, Input, InputButton } from './styles';

Parse.initialize(process.env.PARSE_APPLICATION_ID, process.env.PARSE_JAVASCRIPT_KEY);
Parse.serverURL = process.env.PARSE_HOST_URL;

const Form = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [showModal, setShowModal] = useState({ visible: false, type: 'none' });

  async function addMessage({ email, usename, message }) {
    try {
      // Create a new Parse Object instance
      const Message = new Parse.Object('Message');
      // Define the attributes in the Object
      Message.set('name', usename);
      Message.set('email', email);
      Message.set('message', message);
      Message.set('read', false);
      // Save it on Back4App Data Store
      await Message.save();
      setShowModal({ visible: true, type: 'success' });
      setTimeout(() => { setShowModal({ visible: false, type: 'none' }); }, 2000);

      reset();
    } catch (error) {
      console.log('Error saving new person: ', error);
      setShowModal({ visible: true, type: 'fail' });
      setTimeout(() => { setShowModal({ visible: false, type: 'none' }); }, 2000);
    }
  }

  const onSubmit = data => {
    const { email, name: usename, message } = data;
    // VALIDATE INPUT DATA
    // SEND DATA TO B4A
    addMessage({ email, usename, message });
  };

  console.log('errors', errors);

  return (
    <Wrapper>
      {showModal.visible && <Modal type={showModal.type} />}
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="fieldset">
          <span className="field">
            <span className="label">Email:</span>
            <Input
              type="email"
              name="email"
              aria-label="email"
              {...register('email', { required: true })}
              placeholder={"Your email.."}
              error={errors.email as unknown as string}
            />
          </span>

          <span className="field">
            <span className="label">Fullname:</span>
            <Input
              type="text"
              name="name"
              aria-label="name"
              {...register('name', { required: true })}
              placeholder="Your name.."
              error={errors.name  as unknown as string}
            />
          </span>
        </div>

        <div className="fieldset" style={{ flexDirection: 'column' }}>
          <div className="field">
            <span className="label">Message:</span>
            <Textarea
              name="message"
              aria-label="message"
              {...register('message', { required: true })}
              placeholder="Your message goes here..."
              error={errors.message  as unknown as string}
            />
          </div>
          <InputButton type="submit" value="SEND" />
        </div>
      </form>
    </Wrapper>
  );
};

export default Form;
