import React from 'react';
import CodeBlock from './components/CodeBlock';
import Comment from './components/Comment';
import Tag from './components/Tag';
import Text from './components/Text';
import Heading from './components/Heading';

const txt1 = "Hello beautiful human!";
const txt2 = "My name is Theodoros Vragkos and I am a Software engineer with main focus on the Frontend part of a web application.";
const txt3 = "Before going further...Thank you for visiting my page!";
const txt4 = "I was born in Ukraine, grew up and spend most of my life at the sunny Greece.";
const txt5 = "Since 2018 I am living in the Europian Techhub. The rainy Netherlands!";

const Introduction = ({ title, ...props }) => (
  <div {...props}>
     {title && <Heading content={title} />}
    <CodeBlock>
      <Comment text="Introduction" />
      <Tag name="div" />
      <br />
      <Tag className="p-left" name="h1" />
      <Text className="p-left-2" text={txt1} />
      <Tag className="p-left"name="h1" close />
      <br />
      <Tag className="p-left" name="p" />
      <Text className="p-left-2" text={txt2} />
      <Text className="p-left-2" text={txt3} />
      <Tag className="p-left-2" name="br /" />
      <Text className="p-left-2" text={txt4} />
      <Text className="p-left-2" text={txt5} />
      <Tag className="p-left" name="p" close />
      <br />
      <Tag name="div" close />
    </ CodeBlock>
  </div>
);

export default Introduction;