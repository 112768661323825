import styled from 'styled-components';
import { minMediaQuery } from '../../config/breakpoints';

interface errorProps {
  error: string;
}

export const Wrapper = styled.div`
  padding: 24px;
  background-image: linear-gradient(#fff, #06262fff);
  display: flex;
  justify-content: center;

  .form {
    flex-direction: column;
    width: 100%;
  }

  ${minMediaQuery('md')} {
    .form {
      flex-direction: row;
      max-width: 600px;
    }
  }

  .fieldset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${minMediaQuery('md')} {
      flex-direction: row;
    }
  }

  .field {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Input = styled.input`
  padding: 12px;
  border: none;
  border-left: 6px solid #336699;
  ${({ error }: errorProps) => error && 'border-left: 8px solid red;'}
  margin: 24px 0;
  transition: all 0.2s linear;
  outline: none;
  width: 100%;

  :focus {
    border-left: 8px solid #afff33;
    box-shadow: 4px 4px 3px 0px #afff3333;
  }

`;

export const Textarea = styled.textarea`
  margin-top: 24px;
  padding: 12px;
  border: none;
  border-left: 6px solid #336699;
  ${({ error }: errorProps) => error && 'border-left: 8px solid red;'}
  min-height: 60px;
  min-height: 160px;
  transition: all 0.2s linear;

  :focus {
    outline: none;
    border-left: 8px solid #afff33;
    box-shadow: 4px 4px 3px 0px #afff3333;
  }

  ${minMediaQuery('sm')} {
    min-width: 360px;
  }

  ${minMediaQuery('md')} {
    min-height: 360px;
    min-width: 600px;
  }
`;

export const InputButton = styled.input`
  color: #fafafa;
  padding: 12px;
  border: none;
  margin: 40px auto;
  cursor: pointer;
  transition: background .2s linear;
  width: 100%;
  min-height: 60px;
  align-self: flex-start;
  background-color: #1868C9;

  :focus {
    outline: none;
    border: 4px solid #afff33;
  }

  :hover {
    opacity: 0.9;
    background-color: #336699;
    color: #fafafa;
  }

  :active {
    opacity: 0.8;
    background-color: #afff33;
    color: #444;
  }
`;

export const ErrorMessage = styled.span`
  color: #ff0000;
  font-weight: bold;
`;
