import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header =  styled.div``;

export const Message = styled.div``;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;