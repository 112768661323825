import React from 'react';
import LazyLoad from 'react-lazyload';

import content from './content';
import Card from '../Card';
import { Wrapper, HeadingWrapper, Heading, Grid, Item } from './styles';

const { 'pre-heading': pre, heading, projects } = content;

const ProjetsSection = ({ className, ...props }: { className: string }) => (
  <Wrapper className={className} {...props}>
    <LazyLoad height={200} offset={100}>
      <HeadingWrapper>
        <Heading>
          <span>{`${pre} `}</span>
          {heading}
        </Heading>
      </HeadingWrapper>

      <Grid>
        {projects.map(({
          name, imgSrc, url, desc: {
            type, general, role, features,
          },
        }) => (
          <Item key={name}>
            <Card
              image={imgSrc}
              title={name}
              type={type}
              general={general}
              role={role}
              features={features}
              url={url}
            />
          </Item>
        ))}
      </Grid>
    </LazyLoad>
  </Wrapper>
);

export default ProjetsSection;
