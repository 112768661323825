import React from 'react';
import CodeBlock from './components/CodeBlock';
import Comment from './components/Comment';
import Tag from './components/Tag';
import Text from './components/Text';

const txt1 = "Hello beautiful human!";
const txt2 = "My name is Theodoros Vragkos and I am a Software engineer with main focus on the Frontend part of a web application.";
const txt3 = "Before going further...Thank you for visiting my page!";
const txt4 = "I was born in Lugansk, Ukraine and moved later on, at the age of 8, to the sunny Greece where I grew up and spend most of my life.";
const txt5 = "Since 2018 I am living in the Europian Techhub. The rainy Amsterdam!";

const Hobbies = ({ ...props }) => (
  <CodeBlock {...props}>
    <Comment text="Hobbies" />
    <Tag name="div" />
    <Text text={txt1} />
    <Tag name="div" close />
  </ CodeBlock>
);

export default Hobbies;