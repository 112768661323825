export default {
  'pre-heading': '🔥',
  heading: 'Projects',
  projects: [
    {
      name: 'McDonalds',
      imgSrc: 'https://my-portfolio-opt.s3.eu-central-1.amazonaws.com/mcdonalds.webp',
      desc: {
        type: 'Career website',
        general: 'In this project I was working within a team of three developers using Kanban methodology.',
        role: 'Create reusable components according to the design.',
        features: 'Home page, thank you page, restaurants overview page, restaurant team page.',
      },
      url: 'https://werkenbijmcdonalds.nl/',
    },
    {
      name: 'florinafood.gr',
      imgSrc: 'https://my-portfolio-opt.s3.eu-central-1.amazonaws.com/florinafood.webp',
      desc: {
        type: 'Online food delivery',
        general: 'This is a side project on which I am working in my free time. (Since Jan 2021 I am working I am working on version 2).',
        role: 'Wear all the hats. Analyse and create the web app from UI & UX to API, DevOps.',
        features: 'Contact me for more information. This is a private project.',
      },
      url: 'https://florinafood.gr/',
    },
    {
      name: 'Facilicom',
      imgSrc: 'https://my-portfolio-opt.s3.eu-central-1.amazonaws.com/facilicom.webp',
      desc: {
        type: 'Career website',
        general: 'In this project I was working within a team of three frontend developer using Kanban methodology.',
        role: 'Create reusable components according to the design.',
        features: 'Navigation menu, footer, team sticky search, team sidebar navigation, jobs overview page (jobs search), and the about us page',
      },
      url: 'https://werkenbijfacilicom.nl/',
    },
    {
      name: 'Signify',
      imgSrc: 'https://my-portfolio-opt.s3.eu-central-1.amazonaws.com/signify.webp',
      desc: {
        type: 'Career website',
        general: 'In this project I took the ownership in order to develop the website, based on the new design.',
        role: 'Create reusable components according to the design, update and support the website.',
        features: 'All the components of the websites.',
      },
      url: 'https://www.careers.signify.com/',
    },
  ],
};
