import React, { useState, useEffect } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { useForm } from 'react-hook-form';
import { Message } from '../../components';
import Layout from '../../layout';
import { Header, MessageContainer } from './styles';


const MesssagesContainer = () => {
  const [messages, setMessages] = useState([]);
  const [showErrorMsg, setShowErrorrMsg] = useState(false);
  const { register, reset, handleSubmit } = useForm();

  const sessionPassword = window.sessionStorage.getItem('secret');
  const password = process.env.MESSAGES_KEY;

  const validate = ({ secret }) => {
    if (password === secret) {
      window.sessionStorage.setItem('secret', secret);
      reset();
    } else {
      setShowErrorrMsg(true);
    }
  }

  const fetchMessages = async function () {
    const parseQuery = new Parse.Query('Message');
    let notifications = [];

    try {
      const response = await parseQuery.find();

      response.forEach((msg) => {
        notifications.push({
          email: msg.get('email'),
          name: msg.get('name'),
          message: msg.get('message'),
          read: msg.get('read'),
          timestamp: msg.get('createdAt').toLocaleDateString(),
        });
      });

      setMessages(notifications.reverse());
      return;
    } catch (error) {
      return error.message;
    };
  };

  useEffect(() => { fetchMessages(); }, []);

  return (
    <Layout>
      <Header>INBOX</Header>

      {!sessionPassword && (
        <form onSubmit={handleSubmit(validate)}>
          <input
            type="password"
            name="password"
            defaultValue={sessionPassword}
            {...register('secret')}
          />
          <input type="submit" value="login" />
        </form>
      )}

      {showErrorMsg && <span>SOMETHING WENT WRONG</span>}

      {sessionPassword && <MessageContainer>
        {messages.map(msg => <Message msg={msg} />)}
      </MessageContainer>}
    </Layout>
  );
};

export default MesssagesContainer;
