import React from 'react';
import Layout from '../../layout';

const BlogContainer = () => {
  return (
    <Layout pageName="blog" >BlogContainer</Layout>
  );
};

export default BlogContainer;
