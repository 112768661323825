import React from 'react';
import Link from 'next/link';
import Layout from '../../layout';
import { Wrapper, Message, SubMessage, BackHome } from './styles';

const PrivacyContainer = () => (
  <Layout>
    <Wrapper>
      <Message>The site doesn&apos;t collect any personal or sensitive data! You are safe!</Message>
      <SubMessage>Neither 3rd party tracking services or anything similar!</SubMessage>
      <Link href="/"><BackHome>Back to home page</BackHome></Link>
    </Wrapper>
  </Layout>
);

export default PrivacyContainer;
