/* eslint-disable react/prop-types */
import React from 'react';
import {
  Wrapper, ImageWrapper, Image, Heading, DescriptionWrapper, DescriptionItem,
} from './styles';

const Card = ({
  image, title, type, general, role, features, url,
}) => (
  <Wrapper>
    {image && url && (
      <a aria-label="image link" href={url} rel="noopener noreferrer" target="_blank">
        <ImageWrapper>
          <Image className="image" src={image} />
        </ImageWrapper>
      </a>
    )}

    {title && url && (
      <a aria-label="project title" href={url} rel="noopener noreferrer" target="_blank">
        <Heading>{title}</Heading>
      </a>
    )}

    <DescriptionWrapper>
      {type && (
        <DescriptionItem>
          <span>Type: </span>
          {type}
        </DescriptionItem>
      )}
      {role && (
        <DescriptionItem>
          <span>My role: </span>
          {role}
        </DescriptionItem>
      )}
      {general && (
        <DescriptionItem>
          <span>General: </span>
          {general}
        </DescriptionItem>
      )}
      {features && (
        <DescriptionItem>
          <span>Features: </span>
          {features}
        </DescriptionItem>
      )}
    </DescriptionWrapper>

  </Wrapper>
);

export default Card;
