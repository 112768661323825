import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 3;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding: 24px;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 24px;
  border: 4px solid #afff33;
  background-color: #1868C9;
  width: 80%;
  text-align: center;
`;

export const Message = styled.span`
  color: #fafafa;
`;