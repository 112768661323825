import React from 'react';
import jump from 'jump.js';
import Layout from '../../layout';
import {
  Hero,
  Alien,
  Button,
  Form,
  CodeBlock,
  TechCarousel as Carousel,
  ProjectsSection as Projects,
} from '../../components';
import { ImageWrapper, Container } from './styles';

const HomeContainer = () => {
  const jumpToContact = () => jump('.contact', {
    duration: 1000,
    offset: 0,
  });

  return (
    <Layout>
      <Hero />
      <Container>
        <div className="section bio">
          <ImageWrapper>
            <Alien />
            <Button label="Get in touch" onClick={jumpToContact} />
          </ImageWrapper>
          <CodeBlock.Introduction className="intro" title="A little about me" />
        </div>

        <div className="section">
          <Carousel />
        </div>

        <div className="section">
          <CodeBlock.Experience className="experience" />
        </div>

        <div className="section">
          <Projects className="projects" />
        </div>

        <div className="contact">
          <Form />
        </div>
      </Container>
    </Layout>
  );
};

export default HomeContainer;
