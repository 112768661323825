import React from 'react';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import { getCurrentYear } from '../../functions';
import content from './content';
import { Wrapper, Heading, IconsWrapper, Copyrights, SvgIcon } from './styles';

const {
  'pre-heading': pre,
  heading,
  icons,
} = content;

const styles = {
  color: 'white',
  margin: '0 20px'
};

const Footer = () => (
  <LazyLoad height={200} offset={100}>
    <Wrapper>
      <Heading>
        <span>{pre}</span>
        {heading}
      </Heading>
      <IconsWrapper>
        {icons.map(({ name, url, imgSrc }) => (
          <span key={name}>
            <Link href={url}>
              <a target="_blank" rel="noopener noreferrer">
                <SvgIcon className="image" src={imgSrc} alt={name} width={50} height={50} />
              </a>
            </Link>
          </span>
        ))}
      </IconsWrapper>
      <Copyrights>
      {`© Copyrights ${getCurrentYear()} - Theodoros Vragkos`}
      <Link href="/privacy"><a target="_blank" rel="noopener noreferrer" style={styles}>Privacy policy</a></Link>
    </Copyrights>
    </Wrapper>
  </LazyLoad>
);

export default Footer;