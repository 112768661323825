import html from './img/html-5.svg';
import css from './img/css-3.svg';
import styledComponents from './img/styled-components.svg';
import js from './img/javascript.svg';
import typescript from './img/typescript.svg';
import jest from './img/jest.svg';
import react from './img/react.svg';
import node from './img/nodejs.svg';
import express from './img/express.svg';
// import mongodb from './img/mongodb.svg';
import sequelize from './img/sequelize.svg';
import mysql from './img/mysql-5.svg';

import pwa from './img/pwa.svg';
import parse from './img/parse.svg';
import graphql from './img/graphql.svg';
import firebase from './img/firebase.svg';
import s3 from './img/s3.svg';

export default {
  'pre-heading': 'Tech',
  heading: 'I use ⚙️',
  images: [
    node,
    pwa,
    html,
    parse,
    graphql,
    css,
    js,
    typescript,
    react,
    jest,
    styledComponents,
    firebase,
    s3,
    express,
    sequelize,
    mysql,
  ],
};
