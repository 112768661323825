import React from 'react';
import { StyledButton } from './styles';

interface ButtonProps {
  label: string;
  onClick?: () => void;
}

const Button = ({ label, onClick }: ButtonProps) => {
  const buttonLabel = label.toUpperCase();
  return (
    <StyledButton onClick={onClick}>
      {buttonLabel}
    </StyledButton>
  );
}

export default Button;