import React from 'react';
import Head from 'next/head';
import { Footer } from '../components';

const Layout = ({ pageName='', children }: any) => {
  return (
    <>
      <Head>
        <title>{`${pageName} ${pageName && '|'} Theodoros Vragkos`}</title>
      </Head>
      {children}
      <Footer />
    </>
  );
};

export default Layout;
