import styled from "styled-components";
import { minMediaQuery } from "../../config/breakpoints";

export const Container = styled.div`
  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    ${minMediaQuery('md')} {
      flex-direction: row;
      margin: 80px auto;
    }
  }

  .intro,
  .experience {
    max-width: calc(100% - 40px);
  }

  ${minMediaQuery('md')} {
    .intro,
    .experience {
      max-width: 50%;
    }
  }
`;

export const Heading = styled('h2')`
  background-color: white;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 24px 0;
  letter-spacing: 2px;
  padding: 0 16px;
  text-align: center;

  ${minMediaQuery('md')} {
    font-size: 28px;
    padding: 0;
    text-align: left;
  }

  span {
    font-weight: 300;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto
`;
