import React from 'react';
import { Overlay, Wrapper, Message } from './styles';

interface ModalProps {
  type: string;
}

const Modal = ({ type }: ModalProps) => {
  return (
    <Overlay>
      <Wrapper>
        <Message>
          {type == 'success' ? 'Success! Message received!' : 'Opps, something went wrong...'}
        </Message>
      </Wrapper>
    </Overlay>
  );
};

export default Modal;
