import React from 'react';
import { StyledTag } from './styles';

interface TagProps {
  className?: string,
  name: string,
  close?: boolean,
  padding?: boolean
}

const Tag = ({ name, close=false, ...props }: TagProps) => (
  <StyledTag {...props}>
    &lt;
    {close && '/'}
    {name}
    &gt;
  </StyledTag>
);

export default Tag;
