import React from 'react';
import { Wrapper } from './styles';

const Message = ({ msg: { email, name, message, read, timestamp } }) => (
  <Wrapper read={read}>
    <div>From: {email}</div>
    <div>Username: {name}</div>
    <div>Message: {message}</div>
    <div>Date: {timestamp}</div>
  </Wrapper>
);

export default Message;
