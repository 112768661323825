import React from 'react';
import CodeBlock from './components/CodeBlock';
import Comment from './components/Comment';
import Tag from './components/Tag';
import Text from './components/Text';
import Heading from './components/Heading';

const txt1 = "I love to solve real life problems and help people by building software that makes positive impact in their lives.";
const txt2 = "In the future I see myself code and design alien level software that can be used by billion of users and also easy to understand by all species.";
const txt3 = "In my current role at <a href='https://www.contracthero.de/' rel='noopener noreferrer' target='_blank'>ContractHero</a>, we are building the next generation contract management platform, where people can manage and automate contract related processes. 🖊️ 📜";
const txt4 = `In the past I worked at <a href='https://youngcapital.nl/' rel='noopener noreferrer' target='_blank'>YoungCapital</a>,
<a href='https://www.24i.com/' rel='noopener noreferrer' target='_blank'>24i</a>,
  <a href='https://endouble.com/' rel='noopener noreferrer' target='_blank'>Endouble</a>,
  <a href='https://www.florinafood.gr/' rel='noopener noreferrer' target='_blank'>FlorinaFood</a>
  and did some freelancing at <a href='https://www.upwork.com/' rel='noopener noreferrer' target='_blank'>Upwork</a> in my early days as web developer.`;

const Experience = ({ title, className, ...props }: { title?: string, className?: string }) => (
  <div className={className} {...props}>
    {title && <Heading content={title} />}
    <CodeBlock>
      <Comment text="Experience" />
      <Tag name="div" />
      <br />
      <Tag className="p-left" name="p" />
      <Text className="p-left-2" text={txt1} />
      <Tag className="p-left-2" name="br /" />
      <br />
      <Text className="p-left-2" text={txt2} />
      <Text className="p-left-2" text={txt3} />
      <Tag className="p-left-2" name="br /" />
      <br />
      <Text className="p-left-2" text={txt4} />
      <Tag className="p-left" name="p" close />
      <br />
      <Tag name="div" close />
    </ CodeBlock>
  </div>
);

export default Experience;