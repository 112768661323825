import React from 'react';
import jump from 'jump.js';
import content from './content';
import { Wrapper, Heading, SubTitle, FullName, Role, Arrow } from './styles';

const {
  title,
  preTitle: pre,
  subTitle,
  role,
  fullName,
} = content;

const jumpToBio = () => (
  jump('.bio', {
    duration: 350,
    offset: 0,
    a11y: true,
  })
);

const Hero = () => (
  <Wrapper>
    <Heading>
      <span>{pre}</span>
      {title}
    </Heading>
    <SubTitle>{subTitle}</SubTitle>
    <FullName>{fullName}</FullName>
    <Role>{role}</Role>
    <Arrow aria-label="arrow down" onClick={jumpToBio} />
  </Wrapper>
);

export default Hero;
