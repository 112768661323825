import styled from 'styled-components';
import { minMediaQuery } from '../../../config/breakpoints';

export const Wrapper = styled.div`
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 20px;
  padding: 24px;
  margin: auto;
  width: calc(100% - 24px);
  background: linear-gradient(#2e2e2e, #555);
  border-radius: 10px;

  ${minMediaQuery('md')} {
    font-size: 16px;
    line-height: 24px;
    padding: 24px 32px;
    margin: 0;
    width: calc(100% - 40px);
  }
`;

export const StyledTag = styled.span`
  color: #339eff;
  ${({ padding }: any) => padding && 'padding-left: 8px'};

  ${minMediaQuery('md')} {
    ${({ padding }: any) => padding && 'padding-left: 16px'};
  }
`;

export const StyledComment = styled.div`
  color: #ccc;
`;

export const StyledHeading = styled.h2`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 24px 0;
  letter-spacing: 2px;
  padding: 0 16px;
  text-align: center;
  color: #006699;

  ${minMediaQuery('md')} {
    font-size: 28px;
    padding: 0;
    text-align: left;
  }
  span {
    font-weight: 300;
  }
`;

export const StyledText = styled.div`
  color: #f1fafa;
  padding: auto 8px;

  a {
    color: #afff33;
  };
`;
