import styled, { keyframes } from 'styled-components';
import { minMediaQuery } from '../../config/breakpoints';

const floating = keyframes`
  0% { transform: translate(0, 0); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, 0); }
`;

export const Container = styled.div`
  min-width: 200px;
  min-height: 200px;
  background: url("https://my-portfolio-opt.s3.eu-central-1.amazonaws.com/alien_mobile.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: ${floating} 4s ease-in-out infinite;

  ${minMediaQuery('md')} {
    background: url("https://my-portfolio-opt.s3.eu-central-1.amazonaws.com/alien.webp");
    min-height: 400px;
    min-width: 400px;
  }
`;
