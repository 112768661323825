import React from 'react';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import content from './content';
import { Wrapper, Heading, Img } from './styles';

const {
  'pre-heading': pre,
  heading,
  images,
} = content;

const TechLogoElements = ({ ...props }) => {
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper {...props}>
      <LazyLoad>
      <Heading>
        <span>{`${pre} `}</span>
        {heading}
      </Heading>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...settings}>
          {images.map((logo, index) => (
            <Img key={`key-tech-${index.toString()}`} logo={logo} />
          ))}
        </Slider>
      </LazyLoad>
    </Wrapper>
  );
};

export default TechLogoElements;
