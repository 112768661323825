import styled, { keyframes } from 'styled-components';
import { minMediaQuery } from '../../config/breakpoints';

const animation = keyframes`
  0% { box-shadow: inset 0px -20px 15px 11px #1868c9; }
  50% { box-shadow: inset 0px 0px 15px 11px #006699; }
  100% { box-shadow: inset 0px -20px 15px 11px #1868c9; }
`;

export const StyledButton = styled.button`
  background-image: linear-gradient(#1868c9, #bef5ca);
  color: white;
  border: 4px solid #fff;
  border-radius: 26px;
  cursor: pointer;
  padding: 12px 26px;
  margin: 20px auto;
  width: fit-content;
  font-size: 14px;
  font-weight: semibold;
  box-shadow: inset 1px 12px 15px 11px #006699;
  animation: ${animation} 4s ease-in-out infinite;
  transition: all .2s ease-in-out;
  box-sizing: border-box;

  :focus {
    outline: none;
    border: 4px solid #afff33;
  }

  :hover {
    box-shadow: none;
    border: 4px solid #afff33;
    box-sizing: border-box;
  }

  :active {
    border: 4px solid #bef5ca;
  }

  ${minMediaQuery('md')} {
    font-size: 18px;
    padding: 24px 60px;
  }
`;
